import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AppThemeService, MenuTheme, acceptedThemes } from '../services/app-theme.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { UrlDecoder } from '../services/url-decoder';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})

export class HelpComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  public openDisabled: boolean = true;

  public themes: MenuTheme[] = acceptedThemes;

  private urlDecoder: UrlDecoder = new UrlDecoder();

  public configurationForm: FormGroup = this.formBuilder.group(
    {
      'basic': new FormGroup(
        {
          'tenant': new FormControl('', [Validators.pattern('^[a-z0-9\.\-]+$'), Validators.required]),
          'site': new FormControl('', [Validators.pattern('^[a-zA-Z0-9äÄöÖåÅ]{1,50}$'), Validators.required]),
          'menu': new FormControl('', [Validators.pattern('^[a-zA-Z0-9äÄöÖåÅ]{1,50}$'), Validators.required]),
          'view': new FormControl('week', Validators.required)
        }),
      'content': new FormGroup(
        {
          'mode': new FormControl('currentDate', Validators.required),
          'lang': new FormControl('fi', Validators.required),
          'weekend': new FormControl(true, Validators.required),
          'openAll': new FormControl(false, Validators.required)
        }),
      'appearance': new FormGroup(
        {
          'orientation': new FormControl('portrait', Validators.required),
          'theme': new FormControl('light', Validators.required),
          'zoom': new FormControl('100', [Validators.pattern('^([3-9][0-9]|[1-3][0-9][0-9])$'), Validators.required])
        }),
    });

  @ViewChild('stepper') stepper: MatStepper;

  constructor(
    public appThemeService: AppThemeService,
    private router: Router,
    private formBuilder: FormBuilder,
  ) {
  }

  public themeChange(event: MatSelectChange) {
    this.appThemeService.setTheme(event.value);
  }

  public getView(): string {
    return (this.configurationForm.get('basic')?.value)?.view;
  }

  private getTenant(): string {
    return this.configurationForm.get('basic')?.value?.tenant;
  }

  private getSite(): string {
    return this.configurationForm.get('basic')?.value?.site;
  }

  private getMenu(): string {
    return this.configurationForm.get('basic')?.value?.menu;
  }

  public getMode(): string {
    return this.configurationForm.get('content')?.value?.mode ?? 'currentDate';
  }

  private getLang(): string {
    const lang: string = this.configurationForm.get('content')?.value?.lang ?? 'fi';
    return lang === 'fi' ? '' : lang;
  }

  private getWeekend(): string {
    const weekend: boolean = this.configurationForm.get('content')?.value?.weekend ?? true;
    return weekend === true ? '' : '' + weekend;
  }

  private getOpenAll(): string {
    const openAll: boolean = this.configurationForm.get('content')?.value?.openAll ?? false;
    return openAll === false ? '' : '' + openAll;
  }

  private getOrientation(): string {
    const orientation: string = this.configurationForm.get('appearance')?.value?.orientation != null ? this.configurationForm.get('appearance')?.value?.orientation : 'portrait';
    return orientation === 'portrait' ? '' : orientation;
  }

  private getTheme(): string {
    const theme: string = this.configurationForm.get('appearance')?.value?.theme ?? 'light';
    return theme === 'light' ? '' : theme;
  }

  private getZoom(): string {
    const zoom: number = this.configurationForm.get('appearance')?.value?.zoom != null ? +this.configurationForm.get('appearance')?.value?.zoom : 100;
    return zoom === 100 ? '' : '' + zoom;
  }

  private getUrlWithParam(url: string, param: string, value: string): string {
    if (value == null || (value != null && value === '')) {
      return url;
    }
    return `${url}${url.includes('?') ? '&' : '?'}${param}=${value}`;
  }

  public openUrl(): void {
    if (this.configurationForm.valid === false) {
      return;
    }

    const urlTree = this.router.parseUrl(this.urlDecoder.decode(this.router.url));
    urlTree.queryParams = {};
    urlTree.fragment = null;
    const currentUrl: string = urlTree.toString();
    const baseUrl: string = this.urlDecoder.decode(urlTree.toString().substring(0, currentUrl.lastIndexOf('/help')));

    let goToUrl: string = `${baseUrl}/${this.getView()}/${this.getTenant()}/${this.getSite()}/${this.getMenu()}`;
    if (this.getView() === 'week') {
      goToUrl = this.getUrlWithParam(goToUrl, 'lang', this.getLang());
      goToUrl = this.getUrlWithParam(goToUrl, 'weekend', this.getWeekend());
      goToUrl = this.getUrlWithParam(goToUrl, 'openAll', this.getOpenAll());
      goToUrl = this.getUrlWithParam(goToUrl, 'theme', this.getTheme());
    }
    if (this.getView() === 'presentation') {
      goToUrl = this.getUrlWithParam(goToUrl, 'mode', this.getMode());
      goToUrl = this.getUrlWithParam(goToUrl, 'lang', this.getLang());
      goToUrl = this.getUrlWithParam(goToUrl, 'weekend', this.getWeekend());
      goToUrl = this.getUrlWithParam(goToUrl, 'orientation', this.getOrientation());
      goToUrl = this.getUrlWithParam(goToUrl, 'zoom', this.getZoom());
      goToUrl = this.getUrlWithParam(goToUrl, 'theme', this.getTheme());
    }

    window.open(goToUrl, '_blank');
  }

  ngOnInit(): void {
    this.subscriptions.push(this.configurationForm.valueChanges.subscribe(value => {
      this.openDisabled = !this.configurationForm.valid;
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngAfterViewInit() {
    this.stepper._getIndicatorType = () => 'number';
  }

}
